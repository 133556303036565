@import './fonts/Fonts.css';

:root {
  --dellblue: #007db8;
  --dellgreen: #5cb85c;
  --dellpurple: #6e2585;
  --textlight:  #444444;
  --light: #eeeeee;
}

.blue {
  background-color: var(--dellblue) !important;
}
.blue--text {
  color: var(--dellblue) !important;
}

.green {
  background-color: var(--dellgreen) !important;
  color: white !important;
}
.green--text {
  color: var(--dellgreen) !important;
}
button.green:hover {
  background-color: #579a57 !important;
}

.purple {
  background-color: var(--dellpurple) !important;
  color: white !important;
}
.purple--text {
  color: var(--dellpurple) !important;
}

.light {
  background-color: var(--light) !important;
  color: var(--textlight) !important;
}
.light--text {
  color: var(--textlight) !important;
}
.white--text {
  color: white !important;
}

/* Normal global styles */
input {
  font-size: 16px !important;
}

button {
  font-family: DellReplica-Bold !important;
}

a {
  color: var(--dellblue);
}
