@font-face {
    font-family: DellReplica-Bold;
    src: url('DellReplica-Bold.otf');
}

@font-face {
    font-family: DellReplica-Light;
    src: url('DellReplica-Light.otf');
}

@font-face {
    font-family: DellReplica-Regular;
    src: url('DellReplica-Regular.otf');
}

.font--bold {
    font-family: DellReplica-Bold !important;
}

.font--light {
    font-family: DellReplica-Light !important;
}

.font--regular {
    font-family: DellReplica-Regular !important;
}

.f-42 {
    font-size: 42px !important; 
}

.f-20 {
    font-size: 20px !important; 
}

.f-16 {
    font-size: 16px !important; 
}

.f-15 {
    font-size: 15px !important; 
}

.line-height {
    line-height: 1 !important;
}

.upcase {
    text-transform: uppercase !important;
}

.text-center {
    text-align: center !important;
}