.flip-card.flip {
    transform: rotateY(180deg);
    transition: transform 0.6s;
    transform-style: preserve-3d;
    min-height: 350px;
}

.flip-card-back {
    transform: rotateY(180deg);
    height: 350px !important;
}